<template>
  <main class="contacts-page page">
    <BreadcrumbsComponent title="Контакты" />
    <div class="container-padding">
      <div v-if="info" class="contacts-page__inner">
        <div class="contacts-page__list">
          <div class="contacts-page__item">
            <a v-if="info.kassa" :href="`tel:${info.kassa}`"><b>Касса:</b> {{ info.kassa }}</a>
            <a v-if="info.administrator" :href="`tel:${info.administrator}`">
              <b>Администраторы:</b> {{ info.administrator }}
            </a>
            <a v-if="info.accounting" :href="`tel:${info.accounting}`">
              <b>Бухгалтерия:</b> {{ info.accounting }}
            </a>
            <a v-if="info.fax" :href="`tel:${info.fax}`"><b>Факс:</b> {{ info.fax }}</a>
          </div>
          <div class="contacts-page__item">
            <span><b>Режим работы:</b></span>
            <span v-if="info.working_mode">{{ info.working_mode }}</span>
            <span v-if="info.weekends">{{ info.weekends }}</span>
          </div>
        </div>
        <div class="contacts-page__list">
          <div class="contacts-page__item">
            <span><b>Адрес:</b></span>
            <span v-if="info.address">{{ info.address }}</span>
          </div>
          <div v-if="info.socials && info.socials.length" class="contacts-page__socials">
            <a v-for="(social, i) in info.socials" :key="i" :href="social.link" target="_blank">
              <figure v-if="social.icon" v-html="social.icon" />
            </a>
          </div>
        </div>
        <div v-if="info.address" class="contacts-page__map">
          <yandex-map :settings="settings" :coords="coords" v-if="mapReady" zoom="16">
            <ymap-marker marker-id="0" :coords="coords" />
          </yandex-map>
        </div>
      </div>
      <h2 v-else class="no-info">В данный момент информация отсутствует</h2>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import DEFAULT from "gql/queries/default.graphql";
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "ContactsPage",
  metaInfo: {
    title: "Контакты",
  },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEFAULT,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      });
  },
  data() {
    return {
      mapReady: false,
      coords: [0, 0],
      debounceInterval: null,
      settings: {
        apiKey: "acf7a329-99c5-4322-968e-7d448d951ec7",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
    };
  },
  methods: {
    initMap() {
      loadYmap(this.settings).then(() => {
        window.ymaps.ready(() => {
          window.ymaps.geocode(this.info.address).then((res) => {
            this.coords = res.geoObjects.get(0).geometry.getCoordinates();
            this.mapReady = true;
          });
        });
      });
    },
  },
  mounted() {
    this.initMap();
    this.debounceInterval = setInterval(() => {
      window.addEventListener("resize", () => {
        this.mapReady = false;
        this.$nextTick(() => {
          this.initMap();
        });
      });
    }, 500);
  },
  computed: {
    info() {
      return this.$store.state.contactinfo;
    },
  },
  components: {
    BreadcrumbsComponent,
    yandexMap,
    ymapMarker,
  },
};
</script>

<style lang="stylus">
.contacts-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-template-columns 2fr 2fr
    grid-gap 50px 20px
    +below(800px) {
      grid-template-columns 1fr
    }
  }

  &__list {
    display grid
    grid-gap 30px
    height fit-content

    span, a {
      font-weight 500
      font-size 1em
      line-height 26px
      color var(--dark)
    }
  }

  &__item {
    display grid
    grid-gap 5px
  }

  &__socials {
    display flex
    flex-wrap wrap
    align-items flex-start
    gap 5px

    a {
      background var(--white)
      border-radius 6px
      width 50px
      height 50px
      display flex
      align-items center
      justify-content center

      figure {
        max-width 24px
        max-height: 24px

        svg path {
          fill var(--main)
        }
      }
    }
  }

  &__map {
    height 450px
    border-radius 10px
    overflow hidden
    grid-column span 2
    +below(800px) {
      grid-column auto
    }

    > * {
      height 100%
    }
  }
}
</style>
